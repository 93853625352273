






import {Component, Vue} from "vue-property-decorator";

@Component
export default class LandingPage extends Vue {
	outlined = false

	created() {
		window.addEventListener('scroll', this.checkScroll);
		this.checkScroll
	}
	destroyed() {
		window.removeEventListener('scroll', this.checkScroll);
	}

	checkScroll() {
		this.outlined = document.body.scrollTop === 0
	}
}

